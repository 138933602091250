// src/components/OwnershipPercentageForm.js
import React, { useState } from 'react';
import logo from '../assets/images/Logo.png'; // Adjust the path if necessary

const OwnershipPercentageForm = ({ firstName, onSubmit, onGoBack }) => {
    const [ownershipPercentage, setOwnershipPercentage] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        setOwnershipPercentage(e.target.value);
        if (e.target.value.trim() !== '') {
            setError('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (ownershipPercentage.trim() === '') {
            setError('Ownership percentage cannot be blank');
        } else if (isNaN(ownershipPercentage) || ownershipPercentage < 1 || ownershipPercentage > 100) {
            setError('Please enter a valid percentage between 1 and 100');
        } else {
            onSubmit(ownershipPercentage);
        }
    };


    return (
        <div className="max-w-xl mx-auto p-6 bg-white  text-center">
            <img src={logo} alt="CashAdvance USA Logo" className="mx-auto mb-4 small-logo" />
            <h1 className="text-2xl font-semibold mb-4">
                Amazing! What Is The Exact Ownership Percentage {firstName}?
            </h1>
            <p className="mb-6 text-gray-700">
                We request your ownership percentage to understand your stake in the business, helping us determine loan eligibility and terms.
            </p>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="ownershipPercentage" className="block text-left mb-2">
                        Ownership Percentage
                    </label>
                    <input
                        id="ownershipPercentage"
                        type="number"
                        name="ownershipPercentage"
                        placeholder="1-100%"
                        value={ownershipPercentage}
                        onChange={handleChange}
                        className={`mt-1 block w-full px-3 py-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                    />
                    {error && <span className="text-red-500 text-sm">{error}</span>}
                </div>
                <button type="submit" className="w-full py-2 px-4 submit-btn text-white rounded-lg shadow-md  focus:outline-none">
                    Submit and proceed
                </button>
            </form>
            <button onClick={onGoBack} className="w-full py-2 px-4 mt-4 bg-gray-200 text-gray-700 rounded-lg shadow-md hover:bg-gray-300 focus:outline-none">
                Go Back
            </button>
            <div className="mt-6 text-sm">
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/terms-and-conditions-2-6617" className="terms-policy-btn-color hover:underline">Terms of use</a> ·
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/privacy-policy907306-3708" className="terms-policy-btn-color hover:underline"> Privacy policy</a>
            </div>
        </div>
    );
};

export default OwnershipPercentageForm;
