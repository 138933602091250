// src/components/LenderNamesForm.js
import React, { useState } from 'react';
import logo from '../assets/images/Logo.png'; // Adjust the path if necessary

const LenderNamesForm = ({ onSubmit, onGoBack }) => {
    const [lenderNames, setLenderNames] = useState({
        lenderName1: '',
        lenderName2: '',
        lenderName3: '',
        lenderName4: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLenderNames((prevNames) => ({
            ...prevNames,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(lenderNames);
    };

    return (
        <div className="max-w-xl mx-auto p-6 bg-white  text-center">
            <img src={logo} alt="CashAdvance USA Logo" className="mx-auto mb-4 small-logo" />
            <h1 className="text-2xl font-semibold mb-4">What's The Name Of The Lender?</h1>
            <p className="mb-6 text-gray-700">
                We need to know about lender names because it helps us verify the ownership of collateral, assess the associated risk, secure repayment, and ensure compliance with regulations in our cash advance business.
            </p>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <input
                        type="text"
                        name="lenderName1"
                        placeholder="Lender Name 1"
                        value={lenderNames.lenderName1}
                        onChange={handleChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <div className="mb-4">
                    <input
                        type="text"
                        name="lenderName2"
                        placeholder="Lender Name 2 (Optional)"
                        value={lenderNames.lenderName2}
                        onChange={handleChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <div className="mb-4">
                    <input
                        type="text"
                        name="lenderName3"
                        placeholder="Lender Name 3 (Optional)"
                        value={lenderNames.lenderName3}
                        onChange={handleChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <div className="mb-4">
                    <input
                        type="text"
                        name="lenderName4"
                        placeholder="Lender Name 4 (Optional)"
                        value={lenderNames.lenderName4}
                        onChange={handleChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <button type="submit" className="w-full py-2 px-4 submit-btn text-white rounded-lg shadow-md  focus:outline-none">
                    Submit and proceed
                </button>
            </form>
            <button onClick={onGoBack} className="w-full py-2 px-4 mt-4 bg-gray-200 text-gray-700 rounded-lg shadow-md hover:bg-gray-300 focus:outline-none">
                Go Back
            </button>
            <div className="mt-6 text-sm">
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/terms-and-conditions-2-6617" className="terms-policy-btn-color hover:underline">Terms of use</a> ·
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/privacy-policy907306-3708" className="terms-policy-btn-color hover:underline"> Privacy policy</a>
            </div>
        </div>
    );
};

export default LenderNamesForm;
