import React, { useState } from 'react';
import logo from '../assets/images/Logo.png'; // Adjust the path if necessary

const LoanBalanceForm = ({ onSubmit, onGoBack }) => {
    const [loanBalance, setLoanBalance] = useState('');
    const [error, setError] = useState('');
    const [dontKnow, setDontKnow] = useState(false);

    const handleChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numbers
        setLoanBalance(value);
    };

    const handleCheckboxChange = () => {
        setDontKnow(!dontKnow);
        if (!dontKnow) {
            setLoanBalance('');
            setError('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!dontKnow && loanBalance.trim() === '') {
            setError('Loan balance is required');
        } else {
            onSubmit(dontKnow ? 'Unknown' : loanBalance);
        }
    };

    return (
        <div className="max-w-xl mx-auto p-6 bg-white  text-center">
            <img src={logo} alt="CashAdvance USA Logo" className="mx-auto mb-4 small-logo" />
            <h1 className="text-2xl font-semibold mb-4">Amazing! What Is The Loan Balance?</h1>
            <p className="mb-6 text-gray-700">
                We need your current loan balance to understand your existing financial commitments accurately.
            </p>
            <form onSubmit={handleSubmit}>
                <div className="mb-4 relative">
                    <span className="absolute left-3 top-2.5 text-gray-500">$</span>
                    <input
                        type="text"
                        name="loanBalance"
                        placeholder="Loan Balance"
                        value={loanBalance}
                        onChange={handleChange}
                        disabled={dontKnow}
                        className={`mt-1 block w-full pl-8 px-3 py-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                    />
                    {error && <span className="text-red-500 text-sm">{error}</span>}
                </div>
                <div className="mb-4 text-left">
                    <label className="inline-flex items-center">
                        <input
                            type="checkbox"
                            checked={dontKnow}
                            onChange={handleCheckboxChange}
                            className="form-checkbox"
                        />
                        <span className="ml-2">I Don't Know</span>
                    </label>
                </div>
                <button type="submit" className="w-full py-2 px-4 submit-btn text-white rounded-lg shadow-md  focus:outline-none">
                    Submit and proceed
                </button>
            </form>
            <button onClick={onGoBack} className="w-full py-2 px-4 mt-4 bg-gray-200 text-gray-700 rounded-lg shadow-md hover:bg-gray-300 focus:outline-none">
                Go Back
            </button>
            <div className="mt-6 text-sm">
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/terms-and-conditions-2-6617" className="terms-policy-btn-color hover:underline">Terms of use</a> ·
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/privacy-policy907306-3708" className="terms-policy-btn-color hover:underline"> Privacy policy</a>
            </div>
        </div>
    );
};

export default LoanBalanceForm;
