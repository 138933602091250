// src/components/TitleHoldersForm.js
import React, { useState } from 'react';
import logo from '../assets/images/Logo.png'; // Adjust the path if necessary

const TitleHoldersForm = ({ onSubmit, onGoBack }) => {
    const [titleHolders, setTitleHolders] = useState({
        titleHolder1: '',
        titleHolder2: '',
        titleHolder3: '',
        titleHolder4: '',
    });
    const [dontHaveTitleHolders, setDontHaveTitleHolders] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTitleHolders((prevHolders) => ({
            ...prevHolders,
            [name]: value,
        }));
    };

    const handleCheckboxChange = () => {
        setDontHaveTitleHolders(!dontHaveTitleHolders);
        if (!dontHaveTitleHolders) {
            setTitleHolders({
                titleHolder1: '',
                titleHolder2: '',
                titleHolder3: '',
                titleHolder4: '',
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(dontHaveTitleHolders ? 'None' : titleHolders);
    };

    return (
        <div className="max-w-xl mx-auto p-6 bg-white  text-center">
            <img src={logo} alt="CashAdvance USA Logo" className="mx-auto mb-4 small-logo" />
            <h1 className="text-2xl font-semibold mb-4">Great, And What About Title Holders?</h1>
            <p className="mb-6 text-gray-700">
                We need to know about title holders because it helps us verify the ownership of collateral, assess the associated risk, secure repayment, and ensure compliance with regulations in our cash advance business.
            </p>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <input
                        type="text"
                        name="titleHolder1"
                        placeholder="Title Holder 1"
                        value={titleHolders.titleHolder1}
                        onChange={handleChange}
                        disabled={dontHaveTitleHolders}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <div className="mb-4">
                    <input
                        type="text"
                        name="titleHolder2"
                        placeholder="Title Holder 2 (Optional)"
                        value={titleHolders.titleHolder2}
                        onChange={handleChange}
                        disabled={dontHaveTitleHolders}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <div className="mb-4">
                    <input
                        type="text"
                        name="titleHolder3"
                        placeholder="Title Holder 3 (Optional)"
                        value={titleHolders.titleHolder3}
                        onChange={handleChange}
                        disabled={dontHaveTitleHolders}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <div className="mb-4">
                    <input
                        type="text"
                        name="titleHolder4"
                        placeholder="Title Holder 4 (Optional)"
                        value={titleHolders.titleHolder4}
                        onChange={handleChange}
                        disabled={dontHaveTitleHolders}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <div className="mb-4 text-left">
                    <label className="inline-flex items-center">
                        <input
                            type="checkbox"
                            checked={dontHaveTitleHolders}
                            onChange={handleCheckboxChange}
                            className="form-checkbox"
                        />
                        <span className="ml-2">I Don't Have Title Holders</span>
                    </label>
                </div>
                <button type="submit" className="w-full py-2 px-4 submit-btn text-white rounded-lg shadow-md  focus:outline-none">
                    Submit and proceed
                </button>
            </form>
            <button onClick={onGoBack} className="w-full py-2 px-4 mt-4 bg-gray-200 text-gray-700 rounded-lg shadow-md hover:bg-gray-300 focus:outline-none">
                Go Back
            </button>
            <div className="mt-6 text-sm">
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/terms-and-conditions-2-6617" className="terms-policy-btn-color hover:underline">Terms of use</a> ·
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/privacy-policy907306-3708" className="terms-policy-btn-color hover:underline"> Privacy policy</a>
            </div>
        </div>
    );
};

export default TitleHoldersForm;
