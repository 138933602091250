import React, { useState } from 'react';
import logo from '../assets/images/Logo.png'; // Adjust the path if necessary
import dayjs from 'dayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const DateOfBirthForm = ({ firstName, onSubmit, onGoBack }) => {
    const [dateOfBirth, setDateOfBirth] = useState(dayjs('2000-01-01'));
    const [error, setError] = useState('');

    const handleDateChange = (newDate) => {
        setDateOfBirth(newDate);
        if (newDate) {
            setError('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!dateOfBirth) {
            setError('This is a required field');
        } else {
            onSubmit(dateOfBirth.format('YYYY-MM-DD')); // Formats the date as 'YYYY-MM-DD'
        }
    };

    return (
        <div className="max-w-xl mx-auto p-6 bg-white  text-center">
            <img src={logo} alt="CashAdvance USA Logo" className="mx-auto mb-4 small-logo" />
            <h1 className="text-2xl font-semibold mb-4">
                And What's Your Date Of Birth {firstName}?
            </h1>
            <p className="mb-6 text-gray-700">
                We collect your birthday to ensure compliance with age-related legal requirements and regulations.
            </p>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar
                            views={['year', 'month', 'day']} // Enable 'year', 'month', and 'day' views
                            value={dateOfBirth}
                            onChange={handleDateChange}
                            openTo="day" // Start by opening the 'day' view
                        />
                    </LocalizationProvider>
                    {error && <span className="text-red-500 text-sm">{error}</span>}
                </div>
                <button type="submit" className="w-full py-2 px-4 submit-btn text-white rounded-lg shadow-md  focus:outline-none">
                    Submit and proceed
                </button>
            </form>
            <div className="mt-6 text-sm">
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/terms-and-conditions-2-6617" className="terms-policy-btn-color hover:underline">Terms of use</a> ·
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/privacy-policy907306-3708" className="terms-policy-btn-color hover:underline"> Privacy policy</a>
            </div>
        </div>
    );
};

export default DateOfBirthForm;
