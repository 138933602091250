import React, { useEffect } from 'react';

const BankStatementUpload = ({ onSubmit }) => {
    useEffect(() => {
        // Function to send the height and width to the parent window
        function sendHeightAndWidth() {
            window.parent.postMessage({ location: 'funnel-bank-statement' }, "*");
        }

        sendHeightAndWidth();
        window.addEventListener('resize', sendHeightAndWidth);

        return () => {
            window.removeEventListener('resize', sendHeightAndWidth);
        };
    }, []);
    return (
        <div>loading...</div>
    );
};
export default BankStatementUpload;

// import React, { useEffect, useState } from 'react';
// import logo from '../assets/images/Logo.png'; // Adjust the path if necessary

// const BankStatementUpload = ({ onSubmit }) => {

//     //! when we get the data write on GHL delete this useEffect
//     useEffect(() => {
//         window.location.href = 'https://cashadvance-usa.com/funnel-bank-statement'
//     }, [])

//     // const [files, setFiles] = useState({
//     //     firstMonth: null,
//     //     secondMonth: null,
//     //     thirdMonth: null,
//     //     fourthMonth: null,
//     // });

//     // const [email, setEmail] = useState('');

//     // const handleFileChange = (event, month) => {
//     //     const file = event.target.files[0];
//     //     if (file && isValidFileType(file)) {
//     //         setFiles((prevFiles) => ({
//     //             ...prevFiles,
//     //             [month]: file,
//     //         }));
//     //     } else {
//     //         alert('Please upload a valid file (image, PDF, or DOC).');
//     //     }
//     // };

//     // const isValidFileType = (file) => {
//     //     const validTypes = ['image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
//     //     return validTypes.includes(file.type);
//     // };

//     // const handleUpload = () => {
//     //     if (!files.firstMonth) {
//     //         alert('Please upload the Bank Statement for the 1st Month.');
//     //         return;
//     //     }

//     //     if (!email) {
//     //         alert('Please re-confirm your email.');
//     //         return;
//     //     }

//     //     console.log('Files to be uploaded:', files);
//     //     console.log('Re-confirmed email:', email);
//     //     onSubmit({ files, email });
//     //     // window.location.href = 'https://cashadvance-usa.com/congratulations';
//     // };

//     // const renderFileInput = (id, label, month) => (
//     //     <div className="text-left">
//     //         <label htmlFor={id} className="block text-sm font-medium text-gray-700">
//     //             {label}
//     //         </label>
//     //         <label
//     //             htmlFor={id}
//     //             className={`cursor-pointer mt-1 flex items-center justify-center border-2 border-dashed border-gray-300 rounded-lg h-24 ${files[month] ? 'bg-green-50 border-green-400' : ''}`}
//     //         >
//     //             <input
//     //                 id={id}
//     //                 type="file"
//     //                 className="hidden"
//     //                 onChange={(e) => handleFileChange(e, month)}
//     //                 accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
//     //             />
//     //             <div className="text-center">
//     //                 {files[month] ? (
//     //                     <>
//     //                         <svg className="mx-auto h-8 w-8 text-green-600" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" aria-hidden="true">
//     //                             <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
//     //                         </svg>
//     //                         <span className="text-sm text-green-600 truncate">{files[month].name}</span>
//     //                     </>
//     //                 ) : (
//     //                     <>
//     //                         <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" aria-hidden="true">
//     //                             <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
//     //                         </svg>
//     //                         <span className="text-sm text-gray-500">{label}</span>
//     //                     </>
//     //                 )}
//     //             </div>
//     //         </label>
//     //     </div>
//     // );

//     return (
//         <>
//             <div>loading...</div>
//             {/* <iframe
//                 id="ps-embed-663e33512efe250014b9dc34"
//                 src="https://cashadvance-usa.com/funnel-bank-statement"
//                 width="100%"
//                 height="2200px"
//                 style={{ border: 'none' }}
//             ></iframe> */}
//             {/* <iframe
//                 id="ps-embed-663e33512efe250014b9dc34"
//                 src="https://cashadvance-usa.com/funnel-bank-statement"
//                 style={{ border: 'none', width: '100%', height: '100vh', overflowY: 'scroll' }}
//             ></iframe> */}

//         </>
//         // <div className="max-w-xl mx-auto p-6 bg-white  text-center border border-gray-200">
//         // <img src={logo} alt="CashAdvance USA Logo" className="mx-auto mb-4 small-logo" />
//         //     <h1 className="text-3xl font-semibold mb-6 text-gray-800">
//         //         We Need You To Submit Your Last 4 Bank Statements
//         //     </h1>
//         //     <p className="mb-6 text-gray-600">
//         //         To enhance your qualification for the cash advance, providing your bank statements will be beneficial.
//         //     </p>
//         //     <div className="mt-6 text-left">
//         //         <label htmlFor="email-confirm" className="block text-sm font-medium text-gray-700">
//         //             Please Re-Confirm Your Email *
//         //         </label>
//         //         <input
//         //             type="email"
//         //             id="email-confirm"
//         //             placeholder="Email"
//         //             value={email}
//         //             onChange={(e) => setEmail(e.target.value)}
//         //             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
//         //             required
//         //         />
//         //     </div>
//         //     <div className="space-y-4">
//         //         {renderFileInput('file-input-1', 'Bank Statement 1st Month *', 'firstMonth')}
//         //         {renderFileInput('file-input-2', 'Bank Statement 2nd Month', 'secondMonth')}
//         //         {renderFileInput('file-input-3', 'Bank Statement 3rd Month', 'thirdMonth')}
//         //         {renderFileInput('file-input-4', 'Bank Statement 4th Month', 'fourthMonth')}
//         //     </div>
//         //     <button
//         //         onClick={handleUpload}
//         //         className="w-full py-3 mt-6 bg-green-500 text-white rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-opacity-50"
//         //     >
//         //         Submit
//         //     </button>
//         //     <div className="mt-6 text-sm">
//         //         <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/terms-and-conditions-2-6617" className="terms-policy-btn-color hover:underline">
//         //             Terms of use
//         //         </a>{' '}
//         //         ·{' '}
//         //         <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/privacy-policy907306-3708" className="terms-policy-btn-color hover:underline">
//         //             Privacy policy
//         //         </a>
//         //     </div>
//         // </div>
//     );
// };

// export default BankStatementUpload;
