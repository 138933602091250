import React, { useEffect, useRef } from 'react';
import logo from '../assets/images/Logo.png'; // Adjust the path if necessary

const IndustryQuestion = ({ onSelect }) => {

    const industries = [
        "Advertising",
        "Agriculture",
        "Apparel",
        "Auto Repair",
        "Auto Sales",
        "Banking",
        "Biotechnology",
        "Business Services",
        "Chiropractor",
        "Church",
        "Cleaning / Janitorial",
        "Construction",
        "Consulting",
        "Convenience Store",
        "Dental",
        "E-Commerce",
        "Education",
        "Energy",
        "Engineering",
        "Entertainment",
        "Finance",
        "Gas Station",
        "Government Contractor",
        "Grocery",
        "Home Healthcare",
        "Hotel",
        "HVAC",
        "Insurance",
        "Jewelry",
        "Lawyer",
        "Manufacturing",
        "Medical Practice",
        "Nail Salon",
        "Not For Profit",
        "Optometrist",
        "Other",
        "Plumbing",
        "Real Estate",
        "Recreation",
        "Restaurant and Bar",
        "Retail",
        "Salon",
        "Service",
        "Shipping",
        "Staffing",
        "Technology",
        "Telecommunications",
        "Transportation and Trucking",
        "Veterinary",
        "Wholesale / Distributor"
    ];

    return (
        <div className="max-w-lg mx-auto p-6 bg-white text-center">
            <img src={logo} alt="CashAdvance USA Logo" className="mx-auto mb-4 small-logo" />
            <h1 className="text-2xl font-semibold mb-4">
                What Industry Are You In?
            </h1>
            <p className="mb-6 text-gray-700">
                Please select the industry that best describes your business. This information helps us provide you with the most relevant solutions.
            </p>
            <div className="flex flex-col gap-4">
                {industries.map((industry, index) => (
                    <button
                        key={index}
                        className="submit-btn text-white py-4 px-4 rounded-lg transition duration-300 ease-in-out focus:outline-none"
                        onClick={() => onSelect(industry)}
                    >
                        {industry}
                    </button>
                ))}
            </div>
            <div className="mt-6 text-sm">
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/terms-and-conditions-2-6617" className="terms-policy-btn-color hover:underline">Terms of use</a> ·
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/privacy-policy907306-3708" className="terms-policy-btn-color hover:underline">Privacy policy</a>
            </div>
        </div>
    );
};

export default IndustryQuestion;
