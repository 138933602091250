import React from 'react';
import logo from '../assets/images/Logo.png'; // Adjust the path if necessary


const BankAccountTypeForm = ({ companyName, onSubmit, onGoBack }) => {
    const handleSelect = (type) => {
        onSubmit(type);
    };
    return (
        <div className="max-w-xl mx-auto p-6 bg-white  text-center">
            <img src={logo} alt="CashAdvance USA Logo" className="mx-auto mb-4 small-logo" />
            <h1 className="text-2xl font-semibold mb-4">
                What type of bank account does {companyName} have?
            </h1>
            <p className="mb-6 text-gray-700">
                We ask this to determine our ability to fund your business. It is crucial that you have an active business bank account that has been operational for the past four months.
            </p>
            <p className="mb-6  font-semibold text-lg">
                We do not accept Startups
            </p>
            <div className="flex justify-center space-x-4 mb-4">
                <button
                    onClick={() => handleSelect('business bank account')}
                    className="submit-btn text-white py-4 px-4 rounded-lg transition duration-300 ease-in-out  focus:outline-none"
                >
                    Business Bank Account
                </button>
                <button
                    onClick={() => handleSelect('personal bank account')}
                    className="submit-btn text-white py-4 px-4 rounded-lg transition duration-300 ease-in-out  focus:outline-none"
                >
                    Personal Bank Account
                </button>
            </div>
            <button onClick={onGoBack} className="w-full py-2 px-4 mt-4 bg-gray-200 text-gray-700 rounded-lg shadow-md hover:bg-gray-300 focus:outline-none">
                Go Back
            </button>
            <div className="mt-6 text-sm">
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/terms-and-conditions-2-6617" className="terms-policy-btn-color hover:underline">Terms of use</a> ·
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/privacy-policy907306-3708" className="terms-policy-btn-color hover:underline"> Privacy policy</a>
            </div>
        </div>
    );
};

export default BankAccountTypeForm;
