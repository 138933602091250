import React, { useEffect, useRef } from 'react';
import logo from '../assets/images/Logo.png'; // Adjust the path if necessary
import { useLocation } from 'react-router-dom';

const FundingQuestion = ({ onSelect, onGatingUtmContact, onGatingUtmMedium }) => {
    const location = useLocation(); // Use useLocation within the component
    const prevUtmContent = useRef(null); // Create a ref to store the previous utm_content
    const prevUtmMedium = useRef(null); // Create a ref to store the previous utm_Medium

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const utmContent = params.get('utm_content');

        // Update state only if utmContent exists and is different from the current state
        if (utmContent && utmContent !== prevUtmContent.current) {
            onGatingUtmContact(utmContent);
            prevUtmContent.current = utmContent;
        }

    }, [location, onGatingUtmContact]); // Add dependencies to the useEffect hook

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const utmMedium = params.get('utm_medium');

        // Update state only if utmMedium exists and is different from the current state
        if (utmMedium && utmMedium !== prevUtmMedium.current) {
            onGatingUtmMedium(utmMedium);
            prevUtmMedium.current = utmMedium;
        }
    }, [location, onGatingUtmMedium]); // Add dependencies to the useEffect hook

    const options = [
        '$5,000 - $20,000',
        '$20,000 - $50,000',
        '$50,000 - $100,000',
        '$100,000 - $250,000',
        '$250,000 - $1 Million',
        '$1 Million+'
    ];

    return (
        <div className="max-w-lg mx-auto p-6 bg-white  text-center">
            {/* <div className="max-w-xl mx-auto p-6 bg-white  text-center"> */}
            <img src={logo} alt="CashAdvance USA Logo" className="mx-auto mb-4 small-logo" />
            <h1 className="text-2xl font-semibold mb-4">
                Let's Get You Started On Your Cash Advance Request For Your Business! What Is Your Desired Funding Amount?
            </h1>
            <p className="mb-6 text-gray-700">
                Kindly provide your desired funding amount. This will help us in tailoring the best financial solutions for your needs.
                Expect a brief series of questions, which should require approximately 5 minutes of your time.<br />
                <strong>Intended only for business owners.</strong>
            </p>
            <div className="flex flex-col gap-4">
                {options.map((option, index) => (
                    <button
                        key={index}
                        className="submit-btn text-white py-4 px-4 rounded-lg transition duration-300 ease-in-out  focus:outline-none"
                        onClick={() => onSelect(option)}
                    // style={{ background: '#027BC2' }}
                    >
                        {option}
                    </button>
                ))}
            </div>
            <div className="mt-6 text-sm">
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/terms-and-conditions-2-6617" className="terms-policy-btn-color hover:underline">Terms of use</a> ·
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/privacy-policy907306-3708" className="terms-policy-btn-color hover:underline"> Privacy policy</a>
            </div>
        </div>
    );
};

export default FundingQuestion;
