import React, { useEffect } from 'react';

const ParentComponent = () => {
    // useEffect(() => {
    //     console.log("Sending navigation message to parent from iframe");

    //     // Post the message to the parent window with navigation info
    //     window.parent.postMessage({ type: 'navigation', location: 'fandu' }, '*');
    // }, []);
    useEffect(() => {
        // Function to send the height and width to the parent window
        function sendHeightAndWidth() {
            window.parent.postMessage({ location: 'fundo' }, "*");
        }

        sendHeightAndWidth();
        window.addEventListener('resize', sendHeightAndWidth);

        return () => {
            window.removeEventListener('resize', sendHeightAndWidth);
        };
    }, []);
    return (
        <div>loading...</div>
    );
};

export default ParentComponent;
