import React from 'react';
import logo from '../assets/images/Logo.png'; // Adjust the path if necessary
import { useNavigate } from 'react-router-dom';

const ThankYouPage = ({ firstName, officialBusinessName }) => {
    const navigate = useNavigate(); // Initialize the navigate function

    const handleGetFundingClick = () => {
        navigate('/fundo'); // Use navigate to go to the "/thank-you" route
    };

    return (
        <div className="responsive-height flex items-center justify-center bg-gray-100">
            <div className="bg-white p-8 shadow-md rounded-lg text-center max-w-md">
                <div className="mb-4">
                    <img
                        src={logo}
                        alt="Cash Advance USA"
                        className="mx-auto mb-4"
                    />
                    <h1 className="text-2xl font-semibold mb-2">
                        Congratulations, {firstName}! We’ve found the perfect match for {officialBusinessName}. There are just a few more steps to go!
                    </h1>
                    <p className="text-gray-700 mb-6">
                        We’ve matched you with the best lender in town who is ready to assist you. Click the link below to get your funding started ASAP!
                    </p>
                    <button
                        onClick={handleGetFundingClick}
                        className="w-full py-3 submit-btn text-white rounded-lg shadow-md  focus:outline-none"
                    >
                        Get Your Funding Now
                    </button>
                </div>
                <div className="text-left">
                    <h2 className="text-lg font-semibold mb-2">What’s Next?</h2>
                    <ul className="text-gray-700 mb-4">
                        <li>- Apply in just 5-10 minutes</li>
                        <li>- Get a decision in as fast as 3 hours</li>
                        <li>- Receive funds as quickly as 24 hours</li>
                    </ul>
                    <p className="text-sm text-gray-500">The CashAdvanceUSA Team</p>
                </div>
            </div>
        </div>
    );
};

export default ThankYouPage;
