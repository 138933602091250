import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import FundingQuestion from './components/FundingQuestion';
import BusinessNameForm from './components/BusinessNameForm';
import FirstNameForm from './components/FirstNameForm';
import LastNameForm from './components/LastNameForm';
import MonthlyRevenueForm from './components/MonthlyRevenueForm';
import IndustryForm from './components/IndustryForm';
import ContactForm from './components/ContactForm';
import DateOfBirthForm from './components/DateOfBirthForm';
import FicoScoreForm from './components/FicoScoreForm';
import SsnForm from './components/SsnForm';
import PersonalInfoForm from './components/PersonalInfoForm';
import FundUtilizationForm from './components/FundUtilizationForm';
import LegalStructureForm from './components/LegalStructureForm';
import OwnershipForm from './components/OwnershipForm';
import OwnershipPercentageForm from './components/OwnershipPercentageForm';
import CompanyInfoForm from './components/CompanyInfoForm';
import LiensJudgmentsForm from './components/LiensJudgmentsForm';
import DefaultCashAdvanceForm from './components/DefaultCashAdvanceForm';
import BankruptcyForm from './components/BankruptcyForm';
import CurrentCashAdvanceForm from './components/CurrentCashAdvanceForm';
import LenderNameForm from './components/LenderNameForm';
import CurrentBalanceForm from './components/CurrentBalanceForm';
import PaymentFrequencyForm from './components/PaymentFrequencyForm';
import PaymentAmountForm from './components/PaymentAmountForm';
import CollateralForm from './components/CollateralForm';
import PositionForm from './components/PositionForm';
import PropertyAddressForm from './components/PropertyAddressForm';
import AssetTypeForm from './components/AssetTypeForm';
import PropertyAcquisitionYearForm from './components/PropertyAcquisitionYearForm';
import PurchasePriceForm from './components/PurchasePriceForm';
import PropertyValueForm from './components/PropertyValueForm';
import LoanBalanceForm from './components/LoanBalanceForm';
import OtherLendersForm from './components/OtherLendersForm';
import LenderNamesForm from './components/LenderNamesForm';
import TitleHoldersForm from './components/TitleHoldersForm';
import BankStatementUpload from './components/BankStatementUpload';
import BankAccountTypeForm from './components/BankAccountTypeForm';
import BusinessActiveForm from './components/BusinessActiveForm';
import ThankYouPage from './components/ThankYouPage';
import './index.css';
import './custom.css';
import ProgressBars from './components/ProgressBars';
import Fandu from './components/Fundo';

const useFormState = () => {
  const [state, setState] = useState({
    desiredFunding: null,
    officialBusinessName: null,
    typeOfBankAccount: null,
    businessActivity: null,
    monthlyRevenue: null,
    firstName: null,
    lastName: null,
    industry: null,
    phoneNumberAndEmail: null,
    dateOfBirth: null,
    ficoScore: null,
    socialSecurityNumber: null,
    homeAddress: null,
    utilizationOfFunds: null,
    legalStructure: null,
    owns51Percent: null,
    ownershipPercentage: null,
    businessInfo: null,
    liensOrJudgments: null,
    defaultedCashAdvance: null,
    filedBankruptcy: null,
    currentCashAdvance: null,
    lenderName: null,
    currentBalance: null,
    paymentFrequency: null,
    paymentAmount: null,
    hasCollateral: null,
    position1: null,
    propertyAddress: null,
    assetType: null,
    yearAcquired: null,
    purchasePrice: null,
    currentValue: null,
    loanBalance: null,
    otherLenders: null,
    lenderName1: null,
    titleHolders: null,
    bankStatements: null,
    utmContent: null,
    utmMedium: null,
  });
  const [isQualifiedLead, setIsQualifiedLead] = useState(null);
  const [pixelFired, setPixelFired] = useState({
    qualifiedLead: false,
    unqualifiedLead: false,
  });

  const QualifiedLeadURL = 'https://services.leadconnectorhq.com/hooks/RKQ29EXfnTzKB5PN9er1/webhook-trigger/03e84bf7-234d-42a4-91d3-2a64e9b1de0f';
  const UnQualifiedLeadURL = 'https://services.leadconnectorhq.com/hooks/RKQ29EXfnTzKB5PN9er1/webhook-trigger/9d07c39b-b44e-4395-a99d-8b7402f5516d';

  useEffect(() => {
    determineQualification();

    if (!pixelFired.qualifiedLead && isQualifiedLead && state.phoneNumberAndEmail && !state.businessInfo) {
      fireQualifiedLeadPixel();
    }

    if (!pixelFired.unqualifiedLead && !isQualifiedLead && state.phoneNumberAndEmail) {
      fireUnqualifiedLeadPixel();
    }

  }, [state, isQualifiedLead, pixelFired]);

  const determineQualification = useCallback(() => {
    const isQualified =
      state.typeOfBankAccount === 'business bank account' &&
      state.businessActivity === 'More than a year'
    // state.monthlyRevenue >= 15000;
    setIsQualifiedLead(isQualified);
  }, [state.typeOfBankAccount, state.businessActivity]);

  const fireQualifiedLeadPixel = () => {
    // Inject Google Tag Manager (noscript) iframe
    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-M49SBV9Q";
    iframe.height = "0";
    iframe.width = "0";
    iframe.style.display = "none";
    iframe.style.visibility = "hidden";
    noscript.appendChild(iframe);
    document.body.appendChild(noscript);

    // Ensure fbq is globally available
    window.fbq = window.fbq || function () {
      window.fbq.callMethod ?
        window.fbq.callMethod.apply(window.fbq, arguments) :
        window.fbq.queue.push(arguments)
    };
    if (!window._fbq) window._fbq = window.fbq;
    window.fbq.push = window.fbq;
    window.fbq.loaded = true;
    window.fbq.version = '2.0';
    window.fbq.queue = [];

    // Meta Pixel Code
    (function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ?
          n.callMethod.apply(n, arguments) :
          n.queue.push(arguments)
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s)
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

    // eslint-disable-next-line no-undef
    fbq('init', '8242082332506292');
    // eslint-disable-next-line no-undef
    fbq('track', 'Lead');

    // Append noscript fallback for Meta Pixel
    const noscriptElement = document.createElement('noscript');
    const imgElement = document.createElement('img');
    imgElement.height = "1";
    imgElement.width = "1";
    imgElement.style.display = "none";
    imgElement.src = "https://www.facebook.com/tr?id=8242082332506292&ev=Lead&noscript=1";
    noscriptElement.appendChild(imgElement);
    document.body.appendChild(noscriptElement);

    // Insert the first conversion tracking script
    const script1 = document.createElement('script');
    script1.src = "https://enessedleasant.com/conversion.js?cid=OPTIONAL&payout=OPTIONAL&txid=OPTIONAL&et=lead";
    script1.async = true;
    document.head.appendChild(script1);

    // Insert the second tracking script (new link for qualified lead)
    const script2 = document.createElement('script');
    script2.src = "https://track.cashadvance-usa.com/conversion.js?cid=OPTIONAL&payout=OPTIONAL&txid=OPTIONAL&et=lead";
    script2.async = true;
    document.head.appendChild(script2);

    // Mark this pixel as fired
    setPixelFired((prev) => ({ ...prev, qualifiedLead: true }));
  };

  const fireUnqualifiedLeadPixel = () => {
    // Insert the first conversion tracking script
    const script1 = document.createElement('script');
    script1.src = "https://enessedleasant.com/conversion.js?cid=OPTIONAL&payout=OPTIONAL&txid=OPTIONAL&et=unqualified_lead";
    script1.async = true;
    document.head.appendChild(script1);

    // Insert the second tracking script (new link for unqualified lead)
    const script2 = document.createElement('script');
    script2.src = "https://track.cashadvance-usa.com/conversion.js?cid=OPTIONAL&payout=OPTIONAL&txid=OPTIONAL&et=unqualified_lead";
    script2.async = true;
    document.head.appendChild(script2);

    // Mark this pixel as fired
    setPixelFired((prev) => ({ ...prev, unqualifiedLead: true }));
  };

  const handleSubmit = useCallback(async (updatedState) => {
    const url = isQualifiedLead ? QualifiedLeadURL : UnQualifiedLeadURL;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedState),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  }, [isQualifiedLead]);

  const updateState = (field) => (value) => {
    setState((prevState) => {
      const newState = { ...prevState, [field]: value };
      return newState;
    });
  };

  useEffect(() => {
    if (state.phoneNumberAndEmail || state.currentCashAdvance || state.titleHolders) {
      handleSubmit(state);
    }
  }, [state.phoneNumberAndEmail, state.currentCashAdvance, state.titleHolders]);

  return [state, updateState];
};

const App = () => {
  const [state, updateState] = useFormState();
  const [percentageProcess, setPercentageProcess] = useState(0);

  useEffect(() => {
    // Function to send the height and width to the parent window
    function sendHeightAndWidth() {
      const targetElement = document.querySelector('.responsive-height.flex.items-center.justify-center.bg-gray-100.bg-cover');
      const height = targetElement ? targetElement.offsetHeight : document.body.scrollHeight;
      const width = targetElement ? targetElement.offsetWidth : document.body.scrollWidth;
      window.parent.postMessage({ height, width, location: window.location.href }, "*");
    }

    sendHeightAndWidth();
    window.addEventListener('resize', sendHeightAndWidth);

    return () => {
      window.removeEventListener('resize', sendHeightAndWidth);
    };
  }, []);

  useEffect(() => {
    const totalFields = Object.keys(state).length; // Total number of fields
    const filledFields = Object.values(state).filter(value => value !== null && value !== '').length; // Count of filled fields
    const percentage = (filledFields / totalFields) * 100; // Calculate percentage
    setPercentageProcess(percentage);
  }, [state]);

  const shouldRedirectToThankYouPage =
    (state.typeOfBankAccount === 'personal bank account' ||
      state.businessActivity === 'Less than a year') &&
    state.phoneNumberAndEmail;

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            shouldRedirectToThankYouPage ? (
              <Navigate to="/thank-you" replace />
            ) : (
              <div className="responsive-height flex items-center justify-center bg-cover">
                <div className="">
                  {!state.desiredFunding && <FundingQuestion onSelect={updateState('desiredFunding')} onGatingUtmContact={updateState('utmContent')} onGatingUtmMedium={updateState('utmMedium')} />}
                  {state.desiredFunding && !state.officialBusinessName && (
                    <BusinessNameForm
                      fundingAmount={state.desiredFunding}
                      onSubmit={updateState('officialBusinessName')}
                      onGoBack={() => updateState('desiredFunding')(null)}
                    />
                  )}
                  {state.officialBusinessName && !state.typeOfBankAccount && (
                    <BankAccountTypeForm
                      companyName={state.officialBusinessName}
                      onSubmit={updateState('typeOfBankAccount')}
                      onGoBack={() => updateState('officialBusinessName')(null)}
                    />
                  )}
                  {state.typeOfBankAccount && state.businessActivity === null && (
                    <BusinessActiveForm
                      businessName={state.officialBusinessName}
                      onSubmit={updateState('businessActivity')}
                      onGoBack={() => updateState('typeOfBankAccount')(null)}
                    />
                  )}
                  {state.businessActivity !== null && !state.firstName && (
                    <FirstNameForm
                      onSubmit={updateState('firstName')}
                      onGoBack={() => updateState('businessActivity')(null)}
                    />
                  )}
                  {state.firstName && !state.lastName && (
                    <LastNameForm
                      firstName={state.firstName}
                      onSubmit={updateState('lastName')}
                      onGoBack={() => updateState('firstName')(null)}
                    />
                  )}
                  {state.lastName && !state.monthlyRevenue && (
                    <MonthlyRevenueForm
                      onSubmit={updateState('monthlyRevenue')}
                      onGoBack={() => updateState('lastName')(null)}
                    />
                  )}
                  {state.monthlyRevenue && !state.industry && (
                    <IndustryForm
                      onSelect={updateState('industry')}
                      onGatingUtmContact={updateState('utmContent')}
                    />
                  )}
                  {state.industry && !state.phoneNumberAndEmail && (
                    <ContactForm
                      firstName={state.firstName}
                      onSubmit={updateState('phoneNumberAndEmail')}
                      onGoBack={() => updateState('industry')(null)}
                    />
                  )}
                  {state.phoneNumberAndEmail && !state.dateOfBirth && (
                    <DateOfBirthForm
                      onSubmit={updateState('dateOfBirth')}
                      onGoBack={() => updateState('phoneNumberAndEmail')(null)}
                    />
                  )}
                  {state.dateOfBirth && !state.ficoScore && (
                    <FicoScoreForm
                      firstName={state.firstName}
                      onSubmit={updateState('ficoScore')}
                      onGoBack={() => updateState('dateOfBirth')(null)}
                    />
                  )}
                  {state.ficoScore && !state.socialSecurityNumber && (
                    <SsnForm
                      firstName={state.firstName}
                      onSubmit={updateState('socialSecurityNumber')}
                      onGoBack={() => updateState('ficoScore')(null)}
                    />
                  )}
                  {state.socialSecurityNumber && !state.homeAddress && (
                    <PersonalInfoForm
                      firstName={state.firstName}
                      onSubmit={updateState('homeAddress')}
                      onGoBack={() => updateState('socialSecurityNumber')(null)}
                    />
                  )}
                  {state.homeAddress && !state.utilizationOfFunds && (
                    <FundUtilizationForm
                      firstName={state.firstName}
                      onSubmit={updateState('utilizationOfFunds')}
                      onGoBack={() => updateState('homeAddress')(null)}
                    />
                  )}
                  {state.utilizationOfFunds && !state.legalStructure && (
                    <LegalStructureForm
                      firstName={state.firstName}
                      onSubmit={updateState('legalStructure')}
                      onGoBack={() => updateState('utilizationOfFunds')(null)}
                    />
                  )}
                  {state.legalStructure && state.owns51Percent === null && (
                    <OwnershipForm
                      firstName={state.firstName}
                      onSubmit={updateState('owns51Percent')}
                      onGoBack={() => updateState('legalStructure')(null)}
                    />
                  )}
                  {state.owns51Percent !== null && state.ownershipPercentage === null && (
                    <OwnershipPercentageForm
                      firstName={state.firstName}
                      onSubmit={updateState('ownershipPercentage')}
                      onGoBack={() => updateState('owns51Percent')(null)}
                    />
                  )}
                  {state.ownershipPercentage && !state.businessInfo && (
                    <CompanyInfoForm
                      firstName={state.firstName}
                      onSubmit={updateState('businessInfo')}
                      onGoBack={() => updateState('ownershipPercentage')(null)}
                    />
                  )}
                  {state.businessInfo && !state.liensOrJudgments && (
                    <LiensJudgmentsForm
                      firstName={state.firstName}
                      onSubmit={updateState('liensOrJudgments')}
                      onGoBack={() => updateState('businessInfo')(null)}
                    />
                  )}
                  {state.liensOrJudgments && !state.defaultedCashAdvance && (
                    <DefaultCashAdvanceForm
                      onSubmit={updateState('defaultedCashAdvance')}
                      onGoBack={() => updateState('liensOrJudgments')(null)}
                    />
                  )}
                  {state.defaultedCashAdvance && !state.filedBankruptcy && (
                    <BankruptcyForm
                      onSubmit={updateState('filedBankruptcy')}
                      onGoBack={() => updateState('defaultedCashAdvance')(null)}
                    />
                  )}
                  {state.filedBankruptcy && !state.currentCashAdvance && (
                    <CurrentCashAdvanceForm
                      firstName={state.firstName}
                      onSubmit={updateState('currentCashAdvance')}
                      onGoBack={() => updateState('filedBankruptcy')(null)}
                    />
                  )}
                  {state.currentCashAdvance === 'Yes' && !state.lenderName && (
                    <LenderNameForm
                      onSubmit={updateState('lenderName')}
                      onGoBack={() => updateState('currentCashAdvance')(null)}
                    />
                  )}
                  {state.lenderName && !state.currentBalance && (
                    <CurrentBalanceForm
                      onSubmit={updateState('currentBalance')}
                      onGoBack={() => updateState('lenderName')(null)}
                    />
                  )}
                  {state.currentBalance && !state.paymentFrequency && (
                    <PaymentFrequencyForm
                      onSubmit={updateState('paymentFrequency')}
                      onGoBack={() => updateState('currentBalance')(null)}
                    />
                  )}
                  {state.paymentFrequency && !state.paymentAmount && (
                    <PaymentAmountForm
                      onSubmit={updateState('paymentAmount')}
                      onGoBack={() => updateState('paymentFrequency')(null)}
                    />
                  )}
                  {state.paymentAmount && state.hasCollateral === null && (
                    <CollateralForm
                      onSubmit={updateState('hasCollateral')}
                      onGoBack={() => {
                        updateState('paymentAmount')(null);
                      }}
                    />
                  )}
                  {state.hasCollateral === 'Yes' && !state.position1 && (
                    <PositionForm
                      onSubmit={updateState('position1')}
                      onGoBack={() => updateState('hasCollateral')(null)}
                    />
                  )}
                  {state.hasCollateral === 'Yes' && state.position1 && !state.propertyAddress && (
                    <PropertyAddressForm
                      firstName={state.firstName}
                      onSubmit={updateState('propertyAddress')}
                      onGoBack={() => updateState('position1')(null)}
                    />
                  )}
                  {state.propertyAddress && !state.assetType && (
                    <AssetTypeForm
                      onSubmit={updateState('assetType')}
                      onGoBack={() => updateState('propertyAddress')(null)}
                    />
                  )}
                  {state.assetType && !state.yearAcquired && (
                    <PropertyAcquisitionYearForm
                      firstName={state.firstName}
                      onSubmit={updateState('yearAcquired')}
                      onGoBack={() => updateState('assetType')(null)}
                    />
                  )}
                  {state.yearAcquired && !state.purchasePrice && (
                    <PurchasePriceForm
                      onSubmit={updateState('purchasePrice')}
                      onGoBack={() => updateState('yearAcquired')(null)}
                    />
                  )}
                  {state.purchasePrice && !state.currentValue && (
                    <PropertyValueForm
                      onSubmit={updateState('currentValue')}
                      onGoBack={() => updateState('purchasePrice')(null)}
                    />
                  )}
                  {state.currentValue && !state.loanBalance && (
                    <LoanBalanceForm
                      onSubmit={updateState('loanBalance')}
                      onGoBack={() => updateState('currentValue')(null)}
                    />
                  )}
                  {state.loanBalance && state.otherLenders === null && (
                    <OtherLendersForm
                      onSubmit={updateState('otherLenders')}
                      onGoBack={() => updateState('loanBalance')(null)}
                    />
                  )}
                  {state.otherLenders === 'Yes' && !state.lenderName1 && (
                    <LenderNamesForm
                      onSubmit={updateState('lenderName1')}
                      onGoBack={() => updateState('otherLenders')(null)}
                    />
                  )}
                  {(state.otherLenders === 'No' || state.lenderName1) && !state.titleHolders && (
                    <TitleHoldersForm
                      onSubmit={updateState('titleHolders')}
                      onGoBack={() => updateState('lenderName1')(null)}
                    />
                  )}
                  {(state.titleHolders || state.currentCashAdvance === 'No') && (
                    <BankStatementUpload onSubmit={updateState('bankStatements')} />
                  )}
                  <ProgressBars value={percentageProcess} />
                </div>
              </div>
            )
          }
        />
        <Route path="/thank-you" element={<ThankYouPage firstName={state.firstName} officialBusinessName={state.officialBusinessName} />} />
        <Route path="fundo" element={< Fandu />} />
      </Routes>
    </Router>
  );
};

export default App;