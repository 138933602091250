import React, { useState } from 'react';
import logo from '../assets/images/Logo.png'; // Adjust the path if necessary

const PurchasePriceForm = ({ onSubmit, onGoBack }) => {
    const [purchasePrice, setPurchasePrice] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numbers
        setPurchasePrice(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (purchasePrice.trim() === '') {
            setError('Purchase price is required');
        } else {
            onSubmit(purchasePrice);
        }
    };

    return (
        <div className="max-w-xl mx-auto p-6 bg-white  text-center">
            <img src={logo} alt="CashAdvance USA Logo" className="mx-auto mb-4 small-logo" />
            <h1 className="text-2xl font-semibold mb-4">What Was The Purchase Price?</h1>
            <p className="mb-6 text-gray-700">
                We request the purchase price to understand the initial investment in the property, providing context for its current value assessment and aiding in the evaluation of collateral for your loan application.
            </p>
            <form onSubmit={handleSubmit}>
                <div className="mb-4 relative">
                    <span className="absolute left-3 top-2.5 text-gray-500">$</span>
                    <input
                        type="text"
                        name="purchasePrice"
                        placeholder="Purchase Price"
                        value={purchasePrice}
                        onChange={handleChange}
                        className={`mt-1 block w-full pl-8 px-3 py-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                    />
                    {error && <span className="text-red-500 text-sm">{error}</span>}
                </div>
                <button type="submit" className="w-full py-2 px-4 submit-btn text-white rounded-lg shadow-md  focus:outline-none">
                    Submit and proceed
                </button>
            </form>
            <button onClick={onGoBack} className="w-full py-2 px-4 mt-4 bg-gray-200 text-gray-700 rounded-lg shadow-md hover:bg-gray-300 focus:outline-none">
                Go Back
            </button>
            <div className="mt-6 text-sm">
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/terms-and-conditions-2-6617" className="terms-policy-btn-color hover:underline">Terms of use</a> ·
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/privacy-policy907306-3708" className="terms-policy-btn-color hover:underline"> Privacy policy</a>
            </div>
        </div>
    );
};

export default PurchasePriceForm;
