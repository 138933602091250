// src/components/LenderNameForm.js
import React, { useState } from 'react';
import logo from '../assets/images/Logo.png'; // Adjust the path if necessary

const LenderNameForm = ({ onSubmit, onGoBack }) => {
    const [lenderName, setLenderName] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        setLenderName(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (lenderName.trim() === '') {
            setError('Lender name is required');
        } else {
            onSubmit(lenderName);
        }
    };

    return (
        <div className="max-w-xl mx-auto p-6 bg-white  text-center">
            <img src={logo} alt="CashAdvance USA Logo" className="mx-auto mb-4 small-logo" />
            <h1 className="text-2xl font-semibold mb-4">
                What's Your Lender's Name?
            </h1>
            <p className="mb-6 text-gray-700">
                We request the name of your current lender to better understand your financial commitments and to offer tailored solutions that align with your existing obligations.
            </p>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <input
                        type="text"
                        name="lenderName"
                        placeholder="Lender Name"
                        value={lenderName}
                        onChange={handleChange}
                        className={`mt-1 block w-full px-3 py-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                    />
                    {error && <span className="text-red-500 text-sm">{error}</span>}
                </div>
                <button type="submit" className="w-full py-2 px-4 submit-btn text-white rounded-lg shadow-md  focus:outline-none">
                    Submit and proceed
                </button>
            </form>
            {/* <button onClick={onGoBack} className="w-full py-2 px-4 mt-4 bg-gray-200 text-gray-700 rounded-lg shadow-md hover:bg-gray-300 focus:outline-none">
                Go Back
            </button> */}
            <div className="mt-6 text-sm">
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/terms-and-conditions-2-6617" className="terms-policy-btn-color hover:underline">Terms of use</a> ·
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/privacy-policy907306-3708" className="terms-policy-btn-color hover:underline"> Privacy policy</a>
            </div>
        </div>
    );
};

export default LenderNameForm;
