// src/components/MonthlyRevenueForm.js
import React from 'react';
import logo from '../assets/images/Logo.png'; // Adjust the path if necessary

const MonthlyRevenueForm = ({ businessName, onSubmit, onGoBack }) => {
    const options = [
        { label: '$5,000 - $10,000', value: 10000 },
        { label: '$10,000 - $15,000', value: 15000 },
        { label: '$15,000 - $25,000', value: 25000 },
        { label: '$25,000 - $40,000', value: 40000 },
        { label: '$40,000 - $60,000', value: 60000 },
        { label: '$60,000 - $100,000', value: 100000 },
        { label: '$100,000 - $250,000', value: 250000 },
        { label: '$250,000 - $500,000', value: 500000 },
        { label: '$500,000+', value: 500001 },  // Assuming $500,000+ means anything above $500,000
    ];


    return (
        <div className="max-w-xl mx-auto p-6 bg-white  text-center">
            <img src={logo} alt="CashAdvance USA Logo" className="mx-auto mb-4 small-logo" />
            <h1 className="text-2xl font-semibold mb-4">
                What's {businessName}'s Monthly Revenue?
            </h1>
            <p className="mb-6 text-gray-700">
                To better assess the funding amount suitable for your business, please provide your business's approximate monthly revenue. This information helps us tailor our funding offerings to best meet your needs.
            </p>
            <div className="flex flex-col gap-4">
                {options.map((option, index) => (
                    <button
                        key={index}
                        className="submit-btn text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out  focus:outline-none"
                        onClick={() => onSubmit(option.value)}
                    >
                        {option.label}
                    </button>
                ))}
            </div>
            <button onClick={onGoBack} className="w-full py-2 px-4 mt-4 bg-gray-200 text-gray-700 rounded-lg shadow-md hover:bg-gray-300 focus:outline-none">
                Go Back
            </button>
            <div className="mt-6 text-sm">
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/terms-and-conditions-2-6617" className="terms-policy-btn-color hover:underline">Terms of use</a> ·
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/privacy-policy907306-3708" className="terms-policy-btn-color hover:underline"> Privacy policy</a>
            </div>
        </div>
    );
};

export default MonthlyRevenueForm;
