// src/components/PositionForm.js
import React from 'react';
import logo from '../assets/images/Logo.png'; // Adjust the path if necessary

const PositionForm = ({ onSubmit, onGoBack }) => {
    return (
        <div className="max-w-xl mx-auto p-6 bg-white  text-center">
            <img src={logo} alt="CashAdvance USA Logo" className="mx-auto mb-4 small-logo" />
            <h1 className="text-2xl font-semibold mb-4">Position #1</h1>
            <p className="mb-6 text-gray-700">
                This is the main individual or entity applying for the cash loan. They are primarily responsible for repaying the loan and are typically the one whose creditworthiness and financial information are primarily evaluated during the loan application process.
            </p>
            <button
                className="w-full py-2 px-4 submit-btn text-white rounded-lg shadow-md  focus:outline-none"
                onClick={() => onSubmit(true)}
            >
                Continue
            </button>
            <button onClick={onGoBack} className="w-full py-2 px-4 mt-4 bg-gray-200 text-gray-700 rounded-lg shadow-md hover:bg-gray-300 focus:outline-none">
                Go Back
            </button>
            <div className="mt-6 text-sm">
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/terms-and-conditions-2-6617" className="terms-policy-btn-color hover:underline">Terms of use</a> ·
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/privacy-policy907306-3708" className="terms-policy-btn-color hover:underline"> Privacy policy</a>
            </div>
        </div>
    );
};

export default PositionForm;
