// src/components/OwnershipForm.js
import React, { useState } from 'react';
import logo from '../assets/images/Logo.png'; // Adjust the path if necessary

const OwnershipForm = ({ firstName, onSubmit, onGoBack }) => {
    const [selectedOption, setSelectedOption] = useState(null);

    const handleSubmit = (option) => {
        setSelectedOption(option);
        onSubmit(option);
    };
    return (
        <div className="max-w-xl mx-auto p-6 bg-white  text-center">
            <img src={logo} alt="CashAdvance USA Logo" className="mx-auto mb-4 small-logo" />
            <h1 className="text-2xl font-semibold mb-4">
                Great! And Do You Own At Least 51% {firstName}?
            </h1>
            <p className="mb-6 text-gray-700">
                We are asking if you hold a 51% ownership stake to understand the level of control and influence you have over company decisions. This information helps us assess how decisions are made and who has the authority to implement changes or direct company strategies.
            </p>
            <div className="flex flex-col gap-4">
                <button
                    className={`submit-btn text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out ${selectedOption === 'Yes' ? 'bg-blue-700' : ''} focus:outline-none`}
                    onClick={() => handleSubmit('Yes')}
                >
                    Yes
                </button>
                <button
                    className={`submit-btn text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out ${selectedOption === 'No' ? 'bg-blue-700' : ''} focus:outline-none`}
                    onClick={() => handleSubmit('No')}
                >
                    No
                </button>
            </div>
            <button onClick={onGoBack} className="w-full py-2 px-4 mt-4 bg-gray-200 text-gray-700 rounded-lg shadow-md hover:bg-gray-300 focus:outline-none">
                Go Back
            </button>
            <div className="mt-6 text-sm">
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/terms-and-conditions-2-6617" className="terms-policy-btn-color hover:underline">Terms of use</a> ·
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/privacy-policy907306-3708" className="terms-policy-btn-color hover:underline"> Privacy policy</a>
            </div>
        </div>
    );
};

export default OwnershipForm;
