import React, { useState } from 'react';
import logo from '../assets/images/Logo.png'; // Adjust the path if necessary

const BusinessNameForm = ({ fundingAmount, onSubmit, onGoBack }) => {
    const [businessName, setBusinessName] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        setBusinessName(e.target.value);
        if (e.target.value.trim() !== '') {
            setError('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (businessName.trim() === '') {
            setError('This is a required field');
        } else {
            onSubmit(businessName);
        }
    };

    return (
        <div className="max-w-xl mx-auto p-6 bg-white  text-center">
            <img src={logo} alt="CashAdvance USA Logo" className="mx-auto mb-4 small-logo" />
            <h1 className="text-2xl font-semibold mb-4">
                Could you please provide the official business name requesting between {fundingAmount}?
            </h1>
            <p className="mb-6 text-gray-700">
                The official business name is essential for verifying the legitimacy and legal status of your business, which is crucial for assessing creditworthiness and ensuring compliance with financial regulations.
            </p>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-left">
                        <input
                            type="text"
                            name="businessName"
                            placeholder="Official Business Name"
                            value={businessName}
                            onChange={handleChange}
                            className={`mt-1 block w-full px-3 py-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                        />
                    </label>
                </div>
                {error && <span className="text-red-500 text-sm">{error}</span>}
                <button type="submit" className="w-full mb-4 py-4 px-4 submit-btn text-white rounded-lg shadow-md  focus:outline-none">
                    Submit and proceed
                </button>
            </form>
            <button onClick={onGoBack} className="w-full py-2 px-4 mt-4 bg-gray-200 text-gray-700 rounded-lg shadow-md hover:bg-gray-300 focus:outline-none">
                Go Back
            </button>
            <div className="mt-6 text-sm">
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/terms-and-conditions-2-6617" className="terms-policy-btn-color hover:underline">Terms of use</a> ·
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/privacy-policy907306-3708" className="terms-policy-btn-color hover:underline"> Privacy policy</a>
            </div>
        </div>
    );
};

export default BusinessNameForm;
