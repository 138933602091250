import React, { useState } from 'react';
import logo from '../assets/images/Logo.png'; // Adjust the path if necessary

const states = [
    { name: 'Alabama', abbreviation: 'AL' },
    { name: 'Alaska', abbreviation: 'AK' },
    { name: 'Arizona', abbreviation: 'AZ' },
    { name: 'Arkansas', abbreviation: 'AR' },
    { name: 'California', abbreviation: 'CA' },
    { name: 'Colorado', abbreviation: 'CO' },
    { name: 'Connecticut', abbreviation: 'CT' },
    { name: 'Delaware', abbreviation: 'DE' },
    { name: 'District Of Columbia', abbreviation: 'DC' },
    { name: 'Florida', abbreviation: 'FL' },
    { name: 'Georgia', abbreviation: 'GA' },
    { name: 'Hawaii', abbreviation: 'HI' },
    { name: 'Idaho', abbreviation: 'ID' },
    { name: 'Illinois', abbreviation: 'IL' },
    { name: 'Indiana', abbreviation: 'IN' },
    { name: 'Iowa', abbreviation: 'IA' },
    { name: 'Kansas', abbreviation: 'KS' },
    { name: 'Kentucky', abbreviation: 'KY' },
    { name: 'Louisiana', abbreviation: 'LA' },
    { name: 'Maine', abbreviation: 'ME' },
    { name: 'Maryland', abbreviation: 'MD' },
    { name: 'Massachusetts', abbreviation: 'MA' },
    { name: 'Michigan', abbreviation: 'MI' },
    { name: 'Minnesota', abbreviation: 'MN' },
    { name: 'Mississippi', abbreviation: 'MS' },
    { name: 'Missouri', abbreviation: 'MO' },
    { name: 'Montana', abbreviation: 'MT' },
    { name: 'Nebraska', abbreviation: 'NE' },
    { name: 'Nevada', abbreviation: 'NV' },
    { name: 'New Hampshire', abbreviation: 'NH' },
    { name: 'New Jersey', abbreviation: 'NJ' },
    { name: 'New Mexico', abbreviation: 'NM' },
    { name: 'New York', abbreviation: 'NY' },
    { name: 'North Carolina', abbreviation: 'NC' },
    { name: 'North Dakota', abbreviation: 'ND' },
    { name: 'Ohio', abbreviation: 'OH' },
    { name: 'Oklahoma', abbreviation: 'OK' },
    { name: 'Oregon', abbreviation: 'OR' },
    { name: 'Pennsylvania', abbreviation: 'PA' },
    { name: 'Rhode Island', abbreviation: 'RI' },
    { name: 'South Carolina', abbreviation: 'SC' },
    { name: 'South Dakota', abbreviation: 'SD' },
    { name: 'Tennessee', abbreviation: 'TN' },
    { name: 'Texas', abbreviation: 'TX' },
    { name: 'Utah', abbreviation: 'UT' },
    { name: 'Vermont', abbreviation: 'VT' },
    { name: 'Virginia', abbreviation: 'VA' },
    { name: 'Washington', abbreviation: 'WA' },
    { name: 'West Virginia', abbreviation: 'WV' },
    { name: 'Wisconsin', abbreviation: 'WI' },
    { name: 'Wyoming', abbreviation: 'WY' },
];
const PersonalInfoForm = ({ firstName, onSubmit, onGoBack }) => {
    const [formData, setFormData] = useState({
        street: '',
        unit: '',
        city: '',
        state: '',
        zipCode: ''
    });

    const [errors, setErrors] = useState({});

    const handleChange = ({ target: { name, value } }) => {
        if (name === 'zipCode') {
            // Only allow numeric input
            if (/^\d*$/.test(value)) {
                setFormData({ ...formData, [name]: value });
            }
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.street) formErrors.street = 'Street is required';
        if (!formData.city) formErrors.city = 'City is required';
        if (!formData.state) formErrors.state = 'State is required';
        if (!formData.zipCode) formErrors.zipCode = 'Zip code is required';
        return formErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            onSubmit(formData);
        } else {
            setErrors(formErrors);
        }
    };

    return (
        <div className="max-w-xl mx-auto p-6 bg-white  text-center">
            <img src={logo} alt="CashAdvance USA Logo" className="mx-auto mb-4 small-logo" />
            <h1 className="text-2xl font-semibold mb-4">
                Thank You {firstName}, Please Provide Your Personal Information
            </h1>
            <p className="mb-6 text-gray-700">
                We need to gather information about the individual applying on behalf of {firstName}. This data, which includes the applicant's residence in the United States and Fico score, is crucial for ensuring regulatory compliance and assessing risk. All your data will be kept secure.
            </p>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <input
                        type="text"
                        name="street"
                        placeholder="Your Street"
                        value={formData.street}
                        onChange={handleChange}
                        className={`mt-1 block w-full px-3 py-2 border ${errors.street ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                    />
                    {errors.street && <span className="text-red-500 text-sm">{errors.street}</span>}
                </div>
                <div className="mb-4">
                    <input
                        type="text"
                        name="unit"
                        placeholder="Your Unit (optional)"
                        value={formData.unit}
                        onChange={handleChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <div className="mb-4">
                    <input
                        type="text"
                        name="city"
                        placeholder="Your City"
                        value={formData.city}
                        onChange={handleChange}
                        className={`mt-1 block w-full px-3 py-2 border ${errors.city ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                    />
                    {errors.city && <span className="text-red-500 text-sm">{errors.city}</span>}
                </div>
                <div className="mb-4">
                    <select
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                        className={`mt-1 block w-full px-3 py-2 border ${errors.state ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                    >
                        <option value="">Select Your State</option>
                        {states.map((state) => (
                            <option key={state.abbreviation} value={state.abbreviation}>
                                {state.name}
                            </option>
                        ))}
                    </select>
                    {errors.state && <span className="text-red-500 text-sm">{errors.state}</span>}
                </div>
                <div className="mb-4">
                    <input
                        type="text"
                        name="zipCode"
                        placeholder="Your zip code"
                        value={formData.zipCode}
                        onChange={handleChange}
                        className={`mt-1 block w-full px-3 py-2 border ${errors.zipCode ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                    />
                    {errors.zipCode && <span className="text-red-500 text-sm">{errors.zipCode}</span>}
                </div>
                <button type="submit" className="w-full py-2 px-4 submit-btn text-white rounded-lg shadow-md  focus:outline-none">
                    Submit and proceed
                </button>
            </form>
            <button onClick={onGoBack} className="w-full py-2 px-4 mt-4 bg-gray-200 text-gray-700 rounded-lg shadow-md hover:bg-gray-300 focus:outline-none">
                Go Back
            </button>
            <div className="mt-6 text-sm">
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/terms-and-conditions-2-6617" className="terms-policy-btn-color hover:underline">Terms of use</a> ·
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/privacy-policy907306-3708" className="terms-policy-btn-color hover:underline"> Privacy policy</a>
            </div>
        </div>
    );
};

export default PersonalInfoForm;
