import React, { useState } from 'react';
import logo from '../assets/images/Logo.png'; // Adjust the path if necessary

const PaymentAmountForm = ({ onSubmit, onGoBack }) => {
    const [paymentAmount, setPaymentAmount] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const value = e.target.value;
        if (!isNaN(value)) { // Ensure the value is a number
            setPaymentAmount(value);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (paymentAmount.trim() === '') {
            setError('Payment amount is required');
        } else {
            onSubmit(paymentAmount);
        }
    };

    return (
        <div className="max-w-xl mx-auto p-6 bg-white  text-center">
            <img src={logo} alt="CashAdvance USA Logo" className="mx-auto mb-4 small-logo" />
            <h1 className="text-2xl font-semibold mb-4">
                And What Is The Payment Amount?
            </h1>
            <p className="mb-6 text-gray-700">
                Sum of money that is due for payment on a financial obligation, such as a loan installment or credit card bill.
            </p>
            <form onSubmit={handleSubmit}>
                <div className="mb-4 relative">
                    <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">$</span>
                    <input
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="paymentAmount"
                        placeholder="Payment Amount"
                        value={paymentAmount}
                        onChange={handleChange}
                        className={`mt-1 block w-full pl-8 pr-3 py-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                    />
                    {error && <span className="text-red-500 text-sm">{error}</span>}
                </div>
                <button type="submit" className="w-full py-2 px-4 submit-btn text-white rounded-lg shadow-md  focus:outline-none">
                    Submit and proceed
                </button>
            </form>
            <button onClick={onGoBack} className="w-full py-2 px-4 mt-4 bg-gray-200 text-gray-700 rounded-lg shadow-md hover:bg-gray-300 focus:outline-none">
                Go Back
            </button>
            <div className="mt-6 text-sm">
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/terms-and-conditions-2-6617" className="terms-policy-btn-color hover:underline">Terms of use</a> ·
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/privacy-policy907306-3708" className="terms-policy-btn-color hover:underline"> Privacy policy</a>
            </div>
        </div>
    );
};

export default PaymentAmountForm;
